<template>
  <div
    class="multiple-ratings-item"
    :style="{ border: `1px solid ${colorCodes.titleColor}` }"
  >
    <div class="header-container">
      <div class="avg-rating-title" :style="{ color: colorCodes.titleColor }">
        {{ title }}
      </div>
      <div>
        <span class="avg-rating" :style="{ color: colorCodes.titleColor }">{{
          avgCount
        }}</span>
        <span class="avg-rating-total" :style="{ color: maxStarCountColor }"
          >{{ " " }}/ 5</span
        >
      </div>
      <v-rating
        class="v-rating-add v-rating-read-only"
        :value="avgCount"
        readonly
        half-increments
      ></v-rating>
      <div class="avg-rating-details" :style="{ color: colorCodes.titleColor }">
        {{ `${totalRatings} Ratings` }}
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center">
      <div class="overview-container">
        <div
          class="list-item"
          v-for="(entry, index) in Object.values(overview)"
          :key="index"
        >
          <div
            class="list-item-child"
            :style="{ color: colorCodes.titleColor }"
          >
            {{ Object.values(overview).length - index }}
          </div>
          <div class="list-item-child">
            <v-icon class="individual-star">mdi-star</v-icon>
          </div>
          <div
            class="list-item-child progress-bar-container"
            :style="{ backgroundColor: progressBackgroundColor }"
          >
            <div
              class="progress-bar"
              :style="`width:${(entry * 100) / totalRatings}%`"
            ></div>
          </div>
          <div class="entity-value" :style="{ color: colorCodes.titleColor }">
            {{ entry }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hexToRGBA } from "../../utils/helper";
export default {
  name: "RatingSummery",
  props: {
    title: {
      type: String,
      default: "",
    },
    totalRatings: {
      type: [Number, String],
      default: 0,
    },
    avgCount: {
      type: [Number, String],
      default: 0,
    },
    overview: {
      type: Object,
      default: () => ({}),
    },
    colorCodes: {
      type: Object,
      default: () => ({
        titleColor: "#473068",
        iconColor: "#473068",
        backgroundColor: "#FFFFFF",
      }),
    },
  },
  computed: {
    progressBackgroundColor() {
      const starColorAsRGBA = hexToRGBA(this.colorCodes.iconColor);
      return `rgba(${starColorAsRGBA.r},${starColorAsRGBA.g},${starColorAsRGBA.b},0.2)`;
    },
    maxStarCountColor() {
      const starColorAsRGBA = hexToRGBA(this.colorCodes.backgroundColor);
      return `rgba(${Math.abs(255 - starColorAsRGBA.r)},${Math.abs(
        255 - starColorAsRGBA.g
      )},${Math.abs(255 - starColorAsRGBA.b)},0.6)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-ratings-item {
  padding: 10px 20px;
  border-radius: 8px;
  margin-bottom: 10px;
  width: fit-content;
  height: fit-content;
}
.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.overview-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  margin-top: 10px;
}
.list-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1px 0px;
}
.list-item-child {
  margin: 0px 10px 0px 0px;
  color: $primaryDarkest;
}
.entity-value {
  margin-left: auto;
  color: $primaryDarkest;
}
.avg-rating-title {
  font-size: 16px;
  width: fit-content;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 500;
}
.avg-rating {
  font-size: 24px;
  color: $primaryDarkest;
  font-weight: 500;
}
.avg-rating-total {
  font-size: 16px;
}
.avg-rating-details {
  margin-top: 5px;
  font-size: 14px;
  color: $primaryDarkest;
}
.individual-star {
  color: var(--star-color);
}
.progress-bar-container {
  width: 100px;
}
.progress-bar {
  background-color: var(--star-color);
  height: 12px;
}
.v-rating-add::v-deep .mdi-star {
  color: var(--star-color) !important;
  font-size: 34px;
}
.v-rating-add::v-deep .v-icon {
  padding: 0 3px !important;
}
.v-rating-add::v-deep .mdi-star-outline {
  color: var(--star-color) !important;
  font-size: 34px;
}
.v-rating-add::v-deep .mdi-star-half-full {
  color: var(--star-color) !important;
  font-size: 34px;
}
.v-rating-read-only {
  width: fit-content;
  margin-left: -5px;
}
.v-rating-read-only::v-deep .mdi-star {
  font-size: 24px;
}
.v-rating-read-only::v-deep .mdi-star-outline {
  font-size: 24px;
}
.v-rating-read-only::v-deep .mdi-star-half-full {
  font-size: 24px;
}
</style>
