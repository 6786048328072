<template>
  <div
    class="item-container mb-4"
    :style="`min-height: ${customHeight + 19}px;`"
    @click="navigateToCompanyProfile"
  >
    <div
      class="embed"
      :style="`flex: 0 0 ${customHeight + 21}px; height: ${
        customHeight + 21
      }px; border: 0.5px solid ${colorCodes.iconColor};`"
    >
      <img
        v-if="item.logo"
        class="centered-and-cropped"
        :style="`height:${customHeight}px; width: ${customHeight}px;`"
        :src="getImage()"
        alt="repair-resale-recycle-img"
      />
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        "
        v-else
      >
        <company-icon :fill="colorCodes.iconColor" />
      </div>
    </div>
    <div
      :id="`repair-resale-recycle-item-${type}-${item.id}`"
      class="item-data-container"
      :style="`${
        !expanded ? 'max-height: ' + (customHeight + 17) + 'px;' : ''
      }`"
    >
      <h2
        class="item-name"
        :style="{
          color:
            !entireTraceUnverified && item.verified
              ? colorCodes.verifiedColor
              : colorCodes.unverifiedColor,
        }"
      >
        {{ item.name }}
      </h2>
      <div class="item-data">
        <div
          v-if="item.city && item.country"
          :id="`repair-resale-recycle-item-${type}-${item.id}-location`"
          class="sub-title-container"
        >
          <location-icon
            class="location-icon"
            :fill="
              !entireTraceUnverified && item.verified
                ? colorCodes.verifiedColor
                : colorCodes.unverifiedColor
            "
          />
          <span
            :id="`repair-resale-recycle-item-${type}-${item.id}-location-span`"
            class="sub-title-text tractable"
            :style="{
              color:
                !entireTraceUnverified && item.verified
                  ? colorCodes.verifiedColor
                  : colorCodes.unverifiedColor,
            }"
            >{{ item.city }},{{ " " }}{{ item.country }}</span
          >
        </div>
      </div>
    </div>
    <div
      class="expand-icon-container"
      :style="`height: ${customHeight + 19}px; ${
        displayExpand
          ? `display: flex; ${!displayExpand ? 'flex-direction: column;' : ''}`
          : 'display: none;'
      }`"
    >
      <div
        v-if="displayExpand && !expandWithPopup"
        class="expand-icon"
        @click.stop="changeContainerHeight"
      >
        <v-icon :style="{ color: colorCodes.titleColor }">{{
          expanded ? "mdi-menu-up" : "mdi-menu-down"
        }}</v-icon>
      </div>
      <div
        v-else-if="displayExpand"
        class="details-show-btn mt-2"
        @click.stop="openDetailsWindow(true)"
      >
        <v-icon :style="{ color: colorCodes.titleColor }"
          >mdi-open-in-new</v-icon
        >
      </div>
    </div>
    <popup-window
      :active="detailsWindow"
      title="Suppliers Information"
      :hasBack="false"
      maxWidth="450px"
      minHeight="35vh"
      width="450px"
      :titleStyle="`
        font-size: 16px !important;
        margin-left: 2%;
        color: ${colorCodes.titleColor};
      `"
      closeButtonStyle="padding: 2%;"
      noMargin
      @popup-back="openDetailsWindow(false)"
      @popup-close="openDetailsWindow(false)"
    >
      <div class="details-window">
        <div class="details-container">
          <div class="item-data-inpopup">
            <h2
              class="item-name"
              :style="{
                textAlign: 'left',
                color:
                  !entireTraceUnverified && item.verified
                    ? colorCodes.verifiedColor
                    : colorCodes.unverifiedColor,
              }"
            >
              {{ item.name }}
            </h2>
            <div v-if="item.city && item.country" class="sub-title-container">
              <location-icon
                class="location-icon"
                :fill="
                  !entireTraceUnverified && item.verified
                    ? colorCodes.verifiedColor
                    : colorCodes.unverifiedColor
                "
              />
              <span
                class="sub-title-text tractable"
                :style="{
                  color:
                    !entireTraceUnverified && item.verified
                      ? colorCodes.verifiedColor
                      : colorCodes.unverifiedColor,
                }"
                >{{ item.city }},{{ " " }}{{ item.country }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </popup-window>
  </div>
</template>

<script>
import * as conf from "../../conf.yml";
import routes from "../../router/routes";
import auth from "../../auth";

export default {
  name: "RepairResaleRecycleItem",
  data: () => ({
    baseUrl: conf.default.server.host,
    displayExpand: false,
    expanded: false,
    detailsWindow: false,
  }),

  props: {
    type: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    expandWithPopup: {
      type: Boolean,
      default: false,
    },
    entireTraceUnverified: {
      type: Boolean,
      default: false,
    },
    customHeight: {
      type: Number,
      default: 81,
    },
    colorCodes: {
      type: Object,
      default: () => ({
        titleColor: "#473068",
        iconColor: "#473068",
        verifiedColor: "#473068",
        unverifiedColor: "#EA7A66",
        backgroundColor: "#FFFFFF",
      }),
    },
  },

  watch: {
    expanded: {
      handler: function (val) {
        this.hideHalfContents();
      },
    },
  },

  methods: {
    windowSizeReactor() {
      this.checkExpand();
      this.hideHalfContents();
    },
    openDetailsWindow(value) {
      this.detailsWindow = value;
    },
    getImage() {
      if (this.item.logo)
        return `${this.baseUrl}/api/account/logo_show/${this.item.uuid}/${this.item.logo}`;
    },
    checkExpand() {
      this.hideHalfContents({ reset: true });
      const itemElement = document.getElementById(
        `repair-resale-recycle-item-${this.type}-${this.item.id}`
      );

      if (itemElement.scrollHeight > this.customHeight + 17) {
        itemElement.style.maxWidth = `calc(100% - ${
          this.customHeight + 21
        }px - 30px)`;
        this.displayExpand = true;
      } else {
        this.displayExpand = false;
      }
    },
    changeContainerHeight() {
      this.hideHalfContents({ reset: true });
      const itemElement = document.getElementById(
        `repair-resale-recycle-item-${this.type}-${this.item.id}`
      );
      if (this.expanded) {
        itemElement.style.maxHeight = `${this.customHeight + 17}px`;
        this.expanded = false;
      } else {
        itemElement.style.maxHeight = `${itemElement.scrollHeight}px`;
        this.expanded = true;
      }
    },
    hideHalfContents(options) {
      const itemContainerBottom = document
        .getElementById(
          `repair-resale-recycle-item-${this.type}-${this.item.id}`
        )
        .getBoundingClientRect().bottom;

      const locationElement = document.getElementById(
        `repair-resale-recycle-item-${this.type}-${this.item.id}-location`
      );
      const childLocationSpan = document.getElementById(
        `repair-resale-recycle-item-${this.type}-${this.item.id}-location-span`
      );
      if (locationElement) {
        childLocationSpan.style.webkitLineClamp = "unset";
        locationElement.style.width = "unset";
        locationElement.style.overflow = "unset";
      }

      if (
        !options?.reset &&
        locationElement &&
        locationElement.getBoundingClientRect().bottom > itemContainerBottom
      ) {
        this.isNeedToHideLineReducedElement(
          itemContainerBottom,
          locationElement,
          childLocationSpan
        );
      }
    },
    navigateToCompanyProfile() {
      window.open(
        this.$router.resolve({
          name: routes.COMPANY_PROFILE,
          params: { companyUuid: this.item.company_uuid },
        }).href,
        "_blank"
      );
    },
    reduceLineClamp(element) {
      const computedStyle = window.getComputedStyle(element);
      const paddingTop = parseInt(computedStyle.paddingTop);
      const paddingBottom = parseInt(computedStyle.paddingBottom);
      const borderTop = parseInt(computedStyle.borderTopWidth);
      const borderBottom = parseInt(computedStyle.borderBottomWidth);
      const lineHeight = parseInt(computedStyle.lineHeight);
      const boundingRect = element.getBoundingClientRect();
      const height =
        boundingRect.height -
        paddingTop -
        paddingBottom -
        borderTop -
        borderBottom;
      const numberOfLines = Math.round(height / lineHeight);
      const reducedByOne = numberOfLines - 1;
      if (reducedByOne > 0) {
        element.style.webkitLineClamp = reducedByOne;
        return true;
      }
      return false;
    },
    isNeedToHideLineReducedElement(containerBottom, parentElement, childSpan) {
      let hideLocation = true;
      let reduceLocationLineClamp = true;
      while (reduceLocationLineClamp) {
        reduceLocationLineClamp = this.reduceLineClamp(childSpan);
        if (parentElement.getBoundingClientRect().bottom <= containerBottom) {
          reduceLocationLineClamp = false;
          hideLocation = false;
        }
      }

      if (hideLocation) {
        parentElement.style.width = "0px";
        parentElement.style.overflow = "hidden";
        return true;
      }

      return false;
    },
  },

  created() {
    window.addEventListener("resize", this.windowSizeReactor);
  },

  mounted() {
    this.windowSizeReactor();
  },

  destroyed() {
    window.removeEventListener("resize", this.windowSizeReactor);
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.item-data-container {
  max-width: 100%;
  width: 100%;
  padding: 0px 0px 0px 12px;
  text-align: left;
  overflow-wrap: anywhere;
  overflow-y: hidden;
}

.expand-icon-container {
  margin-right: 10px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: space-between;
}

.expand-icon {
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}

.v-icon.v-icon {
  color: $primary;
}

.item-data {
  color: #473068;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 8px;
}

.embed {
  border-radius: 7px;
  text-align: center;
  position: relative;
}

.sub-title-container {
  display: flex;
  align-items: center;
}

.sub-title-text {
  margin-left: 5px;
  line-height: 14px;
  margin-bottom: 2px;
  text-align: left;
  overflow-wrap: anywhere;
}

.centered-and-cropped {
  object-fit: contain;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.item-name {
  font-size: 15px;
  margin-bottom: 2px;
}
.location-icon {
  margin-bottom: -1px;
  margin-right: 4px;
  flex: 0 0 10px;
}
.tractable {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.details-show-btn {
  width: fit-content;
  height: fit-content;
  padding: 1px;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}
.details-window {
  height: fit-content;
  padding: 0px 2%;
}
.details-container {
  margin-top: 15px;
}
.item-data-inpopup {
  color: #473068;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 10px;
  border-top: 1px solid rgba(71, 48, 104, 0.1);
  padding: 5px;
  padding-top: 15px;
}
</style>
