<template>
  <v-container
    class="parent-container"
    :style="{
      background: colorCodes.backgroundColor,
      '--trace-icon-color': colorCodes.iconColor,
    }"
    fluid
  >
    <div v-if="notFoundTrace" style="height: 100%; width: 100%">
      <NotFound />
    </div>
    <div v-else-if="isPendingTrace" style="height: 100%; width: 100%">
      <TracePending />
    </div>
    <div v-else-if="loading" class="text-center" style="margin: auto">
      <md-progress-spinner
        class="color_stroke"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div v-else class="child-container">
      <v-row>
        <v-col>
          <div
            class="traceability"
            :style="{
              color: colorCodes.iconColor,
            }"
          >
            Digital Product Passport
          </div>
          <div v-if="brandLogoImg" class="brand-logo">
            <img
              v-if="!linkClick || !brandLink"
              class="logo-img"
              :src="brandLogoImg"
              alt="brandLogo"
            />
            <a
              v-else
              target="_blank"
              :href="`//${brandLink.split('//').pop()}`"
            >
              <img class="logo-img" :src="brandLogoImg" alt="brandLogo" />
            </a>
          </div>
          <hr
            class="hr_devider_horizontal"
            :style="`background: linear-gradient(to right, ${colorCodes.titleColor}, ${titleDividerGradientEndColor});`"
          />
          <div class="product-name" :style="{ color: colorCodes.titleColor }">
            {{ productName }}
          </div>
          <div style="margin-top: -5px; margin-bottom: 20px" v-if="showToggle">
            <span class="switch btn-color-mode-switch">
              <input
                type="checkbox"
                name="version"
                id="version"
                value="1"
                v-model="versionChecked"
              />
              <label
                for="version"
                data-on="Current Version"
                data-off="Updated Version"
                class="btn-color-mode-switch-inner"
              >
              </label>
            </span>
          </div>
          <div
            v-if="
              !linkClick &&
              publicLink &&
              (!showToggle || (showToggle && currentVersion))
            "
          >
            <div class="public-link">Get sharable public link</div>
            <div class="url-div">
              <v-icon style="color: #501d5f; transform: rotate(120deg)"
                >link</v-icon
              >
              <span class="url-border"></span>
              <span class="url-span" id="toCopy">{{
                trackingCode || publicLink
              }}</span>
              <md-button
                :class="copied ? 'copy-class' : 'copied-class'"
                @click="copying"
                >{{ copied ? "Copied!" : "Copy" }}</md-button
              >
            </div>
          </div>
          <div v-if="qr && (!showToggle || (showToggle && currentVersion))">
            <qr-code :qr="qr" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="embed">
            <img
              class="centered-and-cropped"
              :style="{
                border: `1px solid ${colorCodes.titleColor}`,
                maxHeight: '50vh',
              }"
              :src="
                defaultImage
                  ? `${baseUrl}/api/inquiries/file_show/${defaultImage}`
                  : sampleImage
              "
              alt="sample image"
            />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="geoLocations && geoLocations.length">
        <v-col>
          <div class="more-info-container">
            <l-map
              ref="map"
              class="geo-map"
              :style="`
                height: 300px;
                z-index: 2;
                border: 1px solid ${colorCodes.titleColor};
                border-radius: 7px;
                background-color: #aad3e0;
              `"
              :options="{ attributionControl: false }"
            >
              <l-tile-layer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="<a href='https://openstreetmap.org'>OpenStreetMap</a>"
                noWrap
              ></l-tile-layer>
              <l-layer-group>
                <l-marker
                  v-for="(geo, index) in geoLocations"
                  :key="`geo${index}`"
                  :lat-lng="[geo.latitude, geo.longitude]"
                >
                  <l-icon :icon-size="[40, 55]" :icon-anchor="[20, 55]">
                    <div>
                      <div
                        class="geo-marker"
                        :style="`background-color: ${colorCodes.iconColor}`"
                      >
                        <div
                          class="geo-marker-image"
                          :style="`background-image: url(${`${geo.icon}`});`"
                        ></div>
                      </div>
                    </div>
                  </l-icon>
                  <l-tooltip>{{ geo.name }}</l-tooltip>
                </l-marker>
              </l-layer-group>
              <l-control-attribution
                position="bottomright"
                prefix=""
              ></l-control-attribution>
            </l-map>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="traceView === 'expanded' && fabricComposition">
        <v-col>
          <div class="more-info-container">
            <trace-component-item
              :color-codes="colorCodes"
              :fabric-composition="fabricComposition"
              :isCompact="false"
              :isMobile="true"
            />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="traceView === 'expanded' && careInstructions.length">
        <v-col>
          <div class="more-info-container">
            <trace-component-item
              :color-codes="colorCodes"
              :care-instructions="careInstructions"
              :isCompact="false"
              :isMobile="true"
            />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="traceView === 'expanded' && sustainability">
        <v-col>
          <div class="more-info-container">
            <trace-component-item
              :color-codes="colorCodes"
              :sustainability="sustainability"
              :isCompact="false"
              :isMobile="true"
            />
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="
          traceView === 'expanded' &&
          ((recycles && recycles.length) ||
            (repairs && repairs.length) ||
            (donations && donations.length) ||
            (upcycles && upcycles.length) ||
            (resales && resales.length))
        "
      >
        <v-col>
          <div class="more-info-container">
            <trace-component-item
              :color-codes="colorCodes"
              :isRepairResaleRecycle="true"
              :resales="resales"
              :recycles="recycles"
              :repairs="repairs"
              :donations="donations"
              :upcycles="upcycles"
              :isCompact="false"
              :isMobile="true"
              :entireTraceUnverified="entireTraceUnverified"
            />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="traceView === 'expanded'">
        <v-col>
          <div
            v-if="displaySupplyChain"
            class="more-info-container supply-chain-label"
          >
            <trace-component-item
              :color-codes="colorCodes"
              :displaySupplyChain="displaySupplyChain"
              :supply-chain="traceDetails"
              :delivery-methods="deliveryMethods"
              :isCompact="false"
              :isMobile="true"
              :entireTraceUnverified="entireTraceUnverified"
            />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="traceView === 'expanded' && bomItems && bomItems.length">
        <v-col>
          <div class="more-info-container">
            <trace-component-item
              :color-codes="colorCodes"
              :bom="bomItems"
              :isCompact="false"
              :isMobile="true"
              :entireTraceUnverified="entireTraceUnverified"
            />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="traceView === 'compact'">
        <v-col cols="12">
          <trace-compact
            :fabricComposition="fabricComposition"
            :careInstructions="careInstructions"
            :sustainability="sustainability"
            :displaySupplyChain="displaySupplyChain"
            :supplyChain="traceDetails"
            :bom="bomItems"
            :deliveryMethods="deliveryMethods"
            :colorCodes="colorCodes"
            :entireTraceUnverified="entireTraceUnverified"
            :isAddReviewVisible="isAddReviewVisible"
            :styleNumber="$route.params.styleNumber"
            :isPreview="preview"
            :isReviewComponent="
              !forceHideReviewComponent && isReviewComponentVisible
            "
            :isMobile="true"
            :isRepairResaleRecycle="
              (recycles && recycles.length) ||
              (repairs && repairs.length) ||
              (donations && donations.length) ||
              (upcycles && upcycles.length) ||
              (resales && resales.length)
            "
            :resales="resales"
            :recycles="recycles"
            :repairs="repairs"
            :donations="donations"
            :upcycles="upcycles"
          />
        </v-col>
      </v-row>
      <div
        v-if="
          displaySupplyChain ||
          (bomItems && bomItems.length) ||
          (recycles && recycles.length) ||
          (repairs && repairs.length) ||
          (donations && donations.length) ||
          (upcycles && upcycles.length) ||
          (resales && resales.length)
        "
        class="statuses"
        tabindex="0"
      >
        <div
          class="supplier-status"
          v-for="(status, index) in statuses"
          :key="`statuses${index}`"
        >
          <v-tooltip
            :ref="'tooltip' + index"
            top
            :color="
              status === 'Verified'
                ? colorCodes.verifiedColor
                : colorCodes.unverifiedColor
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <div data-app>
                <div v-bind="attrs" v-on="on">
                  <div>
                    <v-icon
                      :style="{
                        color:
                          status === 'Verified'
                            ? colorCodes.verifiedColor
                            : colorCodes.unverifiedColor,
                        'margin-top': '-5px',
                      }"
                      >circle</v-icon
                    >
                    <span
                      :style="{
                        color:
                          status === 'Verified'
                            ? colorCodes.verifiedColor
                            : colorCodes.unverifiedColor,
                        'margin-top': '20px',
                        'margin-left': '15px',
                      }"
                    >
                      {{ " " + status }}</span
                    >
                  </div>
                </div>
              </div>
            </template>
            <div class="description-child-container">
              <div class="mt-1">
                {{
                  status === "Verified"
                    ? "Seamless Source verifies the authenticity of registered businesses based on the documents they submit. Each business then independently verifies transactions through the Seamless Source platform and technology."
                    : "Seamless Source does not have sufficient information to validate this business's authenticity. Each business then independently verifies transactions through the Seamless Source platform and technology."
                }}
              </div>
            </div>
          </v-tooltip>
        </div>
      </div>
      <v-row
        v-if="
          traceView === 'expanded' &&
          !forceHideReviewComponent &&
          isReviewComponentVisible
        "
      >
        <v-col cols="12">
          <div class="more-info-container">
            <trace-component-item
              :isAddReviewVisible="isAddReviewVisible"
              :styleNumber="$route.params.styleNumber"
              :isPreview="preview"
              :colorCodes="colorCodes"
              :isReviewComponent="true"
              :isCompact="false"
              :isMobile="true"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="!notFoundTrace && !isPendingTrace && !loading"
      class="logo_container_seamless"
      :style="seamlessLogoContainerStyle"
    >
      <div class="powered-by-label">
        <span>Powered By</span>
        <img
          v-if="!linkClick"
          class="logo-img-seamless"
          src="../../assets/seamless-source-banner.png"
          alt="Seamless Source"
        />
        <a v-else href="https://seamlesssource.com/">
          <img
            class="logo-img-seamless"
            src="../../assets/seamless-source-banner.png"
            alt="Seamless Source"
          />
        </a>
      </div>
    </div>
  </v-container>
</template>

<script>
import linkify from "vue-linkify";
import restAdapter from "../../restAdapter";
import NotFound from "../../views/NotFound.vue";
import TracePending from "../../views/inquiry/TracePending.vue";
import QrCode from "../../components/Inquiry/QrCode.vue";
import Vue from "vue";
import TraceCompact from "./TraceCompact.vue";
import TraceComponentItem from "./TraceComponentItem.vue";
import * as conf from "../../conf.yml";
import { hexToRGBA, isLightColor } from "../../utils/helper";
import {
  LMap,
  LTileLayer,
  LMarker,
  LLayerGroup,
  LIcon,
  LControlAttribution,
  LTooltip,
} from "vue2-leaflet";
import { latLngBounds } from "leaflet";

Vue.directive("linkified", linkify);

export default {
  name: "TraceMobile",

  components: {
    NotFound,
    TracePending,
    QrCode,
    TraceCompact,
    TraceComponentItem,
    LMap,
    LTileLayer,
    LMarker,
    LLayerGroup,
    LIcon,
    LControlAttribution,
    LTooltip,
  },

  props: {
    customerWindow: {
      type: Boolean,
      default: false,
    },
    styleNumber: {
      type: String,
      default: null,
    },
    publicLink: {
      type: String,
      default: null,
    },
    traceLink: {
      type: String,
      default: null,
    },
    linkClick: {
      type: Boolean,
      default: true,
    },
    currentVersion: {
      type: Boolean,
      default: true,
    },
    showToggle: {
      type: Boolean,
      default: false,
    },
    qr: {
      type: String,
      default: null,
    },
    trackingCode: {
      type: String,
      default: null,
    },
    isAddReviewVisible: {
      type: Boolean,
      default: false,
    },
    forceHideReviewComponent: {
      type: Boolean,
      default: false,
    },
    previewTraceDetails: {
      type: Array,
      default: () => [],
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    traceDetails: [],
    notFoundTrace: false,
    isPendingTrace: false,
    loading: true,
    showRatio: false,
    productName: "",
    deliveryMethods: [],
    statuses: ["Verified", "Unverified"],
    copied: false,
    versionChecked: false,
    fabricComposition: null,
    careInstructions: [],
    repairs: [],
    resales: [],
    recycles: [],
    upcycles: [],
    donations: [],
    sustainability: null,
    brandLogoImg: null,
    brandLink: null,
    bomItems: [],
    defaultImage: null,
    sampleImage: require(`../../assets/trace/default.svg`),
    baseUrl: conf.default.server.host,
    entireTraceUnverified: false,
    isReviewComponentVisible: false,
    colorCodes: {
      titleColor: "#473068",
      fontColor: "#473068",
      iconColor: "#70d0ce",
      verifiedColor: "#473068",
      unverifiedColor: "#ea7a66",
      backgroundColor: "#ffffff",
    },
    geoLocations: [],
    traceView: "expanded",
  }),
  watch: {
    traceLink: {
      handler: function () {
        this.getTraceDetails();
      },
      immediate: true,
    },
    versionChecked: {
      handler: function (value) {
        this.changeVersion(value);
      },
      immediate: true,
    },
    previewTraceDetails: {
      handler: function () {
        this.preview && this.getTraceDetails();
      },
      immediate: true,
    },
    geoLocations: {
      handler: function () {
        this.$nextTick(this.setMapBounds);
      },
      immediate: true,
    },
  },
  computed: {
    displaySupplyChain() {
      return (
        this.traceDetails &&
        this.traceDetails.length &&
        this.traceDetails.find(
          (trace) => trace.factory?.established_country || trace.delivery_method
        )
      );
    },
    titleDividerGradientEndColor() {
      const endColor = hexToRGBA(this.colorCodes.titleColor);
      return `rgba(${endColor.r}, ${endColor.g}, ${endColor.b}, 0.1)`;
    },
    seamlessLogoContainerStyle() {
      const endColor = hexToRGBA(this.colorCodes.backgroundColor);
      const isLight = isLightColor(endColor);
      if (!isLight) {
        return "filter: brightness(100);";
      }
      return "";
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const tooltipRefs = this.statuses.map(
        (_, index) => this.$refs[`tooltip${index}`]
      );
      tooltipRefs.forEach((tooltip) => {
        if (tooltip && tooltip.length && tooltip[0].isActive) {
          tooltip[0].isActive = false;
        }
      });
    },
    getTraceDetails() {
      if (this.preview) {
        this.setTraceData(this.previewTraceDetails);
        this.loading = false;
        return;
      }
      this.loading = true;
      const url = this.customerWindow
        ? this.traceLink
        : "/api/inquiries/trace/" + this.$route.params.styleNumber;
      restAdapter
        .get(url)
        .then((response) => {
          this.setTraceData(response.data);
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.notFoundTrace = true;
          } else {
            this.isPendingTrace = true;
          }
          this.loading = false;
        });
    },
    setTraceData(traceData) {
      this.colorCodes = traceData.color_codes;
      this.traceDetails = traceData.details.map((detail) => ({
        ...detail,
        factory:
          detail.used_supplier_as_factory &&
          detail.supplier.account?.city &&
          detail.supplier.account?.country
            ? {
                id: `used_supplier_as_factory_${detail.supplier.id}`,
                // eslint-disable-next-line @typescript-eslint/camelcase
                established_country: detail.supplier.account?.country,
                // eslint-disable-next-line @typescript-eslint/camelcase
                established_city: detail.supplier.account?.city,
                certifications: [],
              }
            : detail.factory,
      }));
      this.bomItems = traceData.bom_records;
      this.geoLocations = [
        ...traceData.trace_geo_images,
        ...traceData.bom_geo_images.filter((geo) =>
          this.bomItems.find((item) => item.id === geo.bom_item_id)
        ),
      ].map((geo) => ({
        latitude: geo.geo_image.latitude,
        longitude: geo.geo_image.longitude,
        icon: `${this.baseUrl}/api/geo/preview/${geo.geo_image.upload_name}`,
        name: geo.bom_item_id
          ? this.bomItems.find((item) => item.id === geo.bom_item_id).name
          : this.traceDetails.find((item) => item.trace_id === geo.trace_id)
              .field_name,
      }));
      this.reviews = traceData.reviews;
      this.productName = traceData.product_name;
      this.deliveryMethods = traceData.delivery_methods;
      this.fabricComposition = traceData.fabric_composition;
      this.careInstructions = traceData.care_instructions;
      this.sustainability = traceData.sustainability_and_circularity;
      this.traceView = traceData.trace_view;
      this.resales = traceData.resales;
      this.repairs = traceData.repairs;
      this.recycles = traceData.recycles;
      this.donations = traceData.donations;
      this.upcycles = traceData.upcycles;
      this.brandLogoImg = traceData.brand_logo;
      this.brandLink = traceData.brand_link;
      this.defaultImage = traceData.default_image;
      this.entireTraceUnverified = traceData.mark_whole_trace_as_unverified;
      this.isReviewComponentVisible = traceData.reviews;
      this.$emit("background-color", this.colorCodes.backgroundColor);
    },
    copying() {
      navigator.clipboard
        .writeText(document.getElementById("toCopy").textContent)
        .then(() => (this.copied = true));
      setTimeout(() => {
        this.copied = !this.copied;
      }, 5000);
    },
    changeVersion(isCurrent) {
      this.$emit("change-version", isCurrent);
    },
    setMapBounds() {
      if (this.geoLocations && this.geoLocations.length) {
        const bounds = this.geoLocations.reduce((bounds, geo) => {
          return bounds.extend([geo.latitude, geo.longitude]);
        }, latLngBounds([]));

        this.$refs.map.mapObject.fitBounds(bounds);
      }
    },
  },
};
</script>
<style scoped>
#app {
  all: unset;
}
.theme--light.v-application {
  all: unset;
}
.v-application {
  all: unset;
}
.v-application::v-deep .v-application--wrap {
  all: unset;
}
.container {
  max-width: 100vw;
  height: 100%;
  min-height: 100vh;
}
.parent-container {
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  margin: auto;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.child-container {
  margin-top: 15px;
  width: 95%;
}
.progress {
  margin-top: 20%;
  margin-left: 50%;
  height: 50%;
}
.logo_container_seamless {
  margin-top: 20px;
  align-self: center;
}
.powered-by-label {
  text-align: left;
  font-size: 12px;
  margin-bottom: -3px;
  color: #473068;
}
.logo-img-seamless {
  max-height: 25px;
  margin-left: 5px;
}
.brand-logo {
  float: left;
  max-width: 115px;
  max-height: 115px;
  margin: 10px 0px;
}
.product-name {
  text-align: left;
  margin: 0px 10px 15px 0px;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  overflow-wrap: anywhere;
}

.hr_devider_horizontal {
  height: 3px;
  width: 95%;
  border: none;
  margin: 0px 0px 5px 3px;
  overflow: auto;
}

.traceability {
  text-align: left;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
}

.supplier-status {
  display: inline-block;
  margin: 15px 20px 15px 0px;
  font-size: 18px;
}
.verified-certification {
  display: inline-block;
  margin: 15px 20px 15px 0px;
  font-size: 18px;
}
.trace-container {
  margin-left: 15px;
  text-align: left;
}
.stepper {
  margin: 21px -43px 30px 40px !important;
  min-height: 50px;
  height: auto !important;
  border-left: 3px solid;
  border-image-slice: 1;
  margin: 100px;
}

.content {
  margin: 0px 0px 25px 70px !important;
  font-size: 14px;
}
.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}
.statuses {
  text-align: center;
  margin: 30px 0px 30px 20px;
  z-index: 15;
}
.verified_icon_container {
  font-size: 36px;
  text-decoration: none !important;
  width: 59px;
  height: 59px;
  background: transparent;
  border: 2px solid rgba(71, 48, 104, 0.31);
  box-shadow: 0px 7.66234px 13.7922px rgba(64, 35, 105, 0.21);
  border-radius: 30px;
}

.unverified_icon_container {
  font-size: 36px;
  text-decoration: none !important;
  width: 59px;
  height: 59px;
  background: transparent;
  border: 2px solid rgba(234, 122, 102, 0.31);
  box-shadow: 0px 7.66234px 13.7922px rgba(234, 122, 102, 0.21);
  border-radius: 30px;
}
.icon-container {
  display: flex;
  align-items: center;
}
.title {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
}
.data-field {
  font-size: 16px;
  margin: 5px 0px 5px 0px;
}
.certifications {
  width: 200px;
  height: auto;
  margin-left: -5px;
}
.certification-list {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 2px 5px 2px 5px;
  margin: 5px 5px 5px 5px;
  border-radius: 3px;
}
.footer {
  display: flex;
  justify-content: center;
}

.description-child-container {
  text-align: left !important;
  max-width: 250px !important;
}

.learn-more {
  overflow-x: hidden !important;
  white-space: pre-wrap !important;
  text-align: center;
}

.care-instruction-img {
  width: 50px;
  max-height: 50px;
  margin-right: 15px;
  margin-bottom: 10px;
}
.supply_chain_title {
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px !important;
  line-height: 33px;
  color: #473068;
}

.details_subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.details_data {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.public-link {
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #473068;
  margin: 30px 0px 10px 0px;
  text-align: left;
}
.url-div {
  width: 60%;
  border: 0.5px solid;
  border-color: #501d5f !important;
  border-radius: 8px;
  padding: 6px;
  display: flex;
  align-items: center;
  height: 46px;
  margin-left: 0px;
}
.url-span {
  overflow: hidden;
  color: #c2aae8;
  padding: 5px 6px 2px 5px;
  margin-right: auto;
  overflow: hidden;
}

.url-border {
  border-left: 0.5px solid #501d5f;
  height: 30px;
  margin-left: 4px;
  margin-right: 4px;
}

.copy-class {
  color: #ffffff;
  background-color: #70d0ce;
  border-radius: 3px;
  margin-right: 0px;
  /* float: right; */
}
.copied-class {
  color: #ffffff;
  background-color: #473068;
  border-radius: 3px;
  margin-right: 0px;
  /* float: right; */
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner {
  cursor: pointer;
  margin: 0px;
  width: 282px;
  height: 30px;
  background: #e2daed;
  color: #473068;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
  display: block;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  top: 7px;
  right: 20px;
  color: #473068;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 150px;
  height: 26px;
  background: #ffffff;
  border: 1px solid #473068;
  border-radius: 26px;
  position: absolute;
  left: 2px;
  top: 2px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #e2daed;
  padding: 3px 0px;
}

.btn-color-mode-switch input[type="checkbox"] {
  opacity: 0;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner {
  background: #e2daed;
  color: #473068;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 130px;
  background: #ffffff;
  border: 1px solid #473068;
  box-shadow: 0px 0px 6px -2px #e2daed;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  left: 20px;
}
.logo-img {
  max-width: 115px;
  max-height: 115px;
  margin-left: 2px;
}

.more-info-title {
  margin-left: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.more-info-container {
  text-align: left;
  min-height: 50px;
}
.supply-chain-label {
  margin-top: 10px;
}

.more-info-data {
  margin: 25px 80px 0px 5px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}
.title-underline {
  width: fit-content;
}
.accept-line-breaks {
  white-space: pre-line;
  word-break: break-word;
}
.embed {
  display: flex;
  justify-content: center;
  margin-top: -24px;
}
.centered-and-cropped {
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 7px;
}
.geo-marker {
  transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
  transform-origin: 50% 50%;
  border-radius: 50% 50% 50% 0;
  padding: 0 3px 3px 0;
  width: 40px;
  height: 40px;
  -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
  box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
}
.geo-marker-image {
  position: absolute;
  transform: perspective(40px) rotateX(-20deg) rotateZ(45deg);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-size: cover;
  background-position: center;
  top: 6px;
  left: 2.5px;
}
.geo-map::v-deep .leaflet-control-zoom-in {
  color: var(--trace-icon-color) !important;
  text-decoration: none;
}
.geo-map::v-deep .leaflet-control-zoom-out {
  color: var(--trace-icon-color) !important;
  text-decoration: none;
}
</style>
