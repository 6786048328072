var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-container mb-4",style:(`min-height: ${_vm.customHeight + 19}px;`),on:{"click":_vm.navigateToCompanyProfile}},[_c('div',{staticClass:"embed",style:(`flex: 0 0 ${_vm.customHeight + 21}px; height: ${
      _vm.customHeight + 21
    }px; border: 0.5px solid ${_vm.colorCodes.iconColor};`)},[(_vm.item.logo)?_c('img',{staticClass:"centered-and-cropped",style:(`height:${_vm.customHeight}px; width: ${_vm.customHeight}px;`),attrs:{"src":_vm.getImage(),"alt":"repair-resale-recycle-img"}}):_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","width":"100%","height":"100%"}},[_c('company-icon',{attrs:{"fill":_vm.colorCodes.iconColor}})],1)]),_c('div',{staticClass:"item-data-container",style:(`${
      !_vm.expanded ? 'max-height: ' + (_vm.customHeight + 17) + 'px;' : ''
    }`),attrs:{"id":`repair-resale-recycle-item-${_vm.type}-${_vm.item.id}`}},[_c('h2',{staticClass:"item-name",style:({
        color:
          !_vm.entireTraceUnverified && _vm.item.verified
            ? _vm.colorCodes.verifiedColor
            : _vm.colorCodes.unverifiedColor,
      })},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('div',{staticClass:"item-data"},[(_vm.item.city && _vm.item.country)?_c('div',{staticClass:"sub-title-container",attrs:{"id":`repair-resale-recycle-item-${_vm.type}-${_vm.item.id}-location`}},[_c('location-icon',{staticClass:"location-icon",attrs:{"fill":!_vm.entireTraceUnverified && _vm.item.verified
              ? _vm.colorCodes.verifiedColor
              : _vm.colorCodes.unverifiedColor}}),_c('span',{staticClass:"sub-title-text tractable",style:({
            color:
              !_vm.entireTraceUnverified && _vm.item.verified
                ? _vm.colorCodes.verifiedColor
                : _vm.colorCodes.unverifiedColor,
          }),attrs:{"id":`repair-resale-recycle-item-${_vm.type}-${_vm.item.id}-location-span`}},[_vm._v(_vm._s(_vm.item.city)+","+_vm._s(" ")+_vm._s(_vm.item.country))])],1):_vm._e()])]),_c('div',{staticClass:"expand-icon-container",style:(`height: ${_vm.customHeight + 19}px; ${
      _vm.displayExpand
        ? `display: flex; ${!_vm.displayExpand ? 'flex-direction: column;' : ''}`
        : 'display: none;'
    }`)},[(_vm.displayExpand && !_vm.expandWithPopup)?_c('div',{staticClass:"expand-icon",on:{"click":function($event){$event.stopPropagation();return _vm.changeContainerHeight.apply(null, arguments)}}},[_c('v-icon',{style:({ color: _vm.colorCodes.titleColor })},[_vm._v(_vm._s(_vm.expanded ? "mdi-menu-up" : "mdi-menu-down"))])],1):(_vm.displayExpand)?_c('div',{staticClass:"details-show-btn mt-2",on:{"click":function($event){$event.stopPropagation();return _vm.openDetailsWindow(true)}}},[_c('v-icon',{style:({ color: _vm.colorCodes.titleColor })},[_vm._v("mdi-open-in-new")])],1):_vm._e()]),_c('popup-window',{attrs:{"active":_vm.detailsWindow,"title":"Suppliers Information","hasBack":false,"maxWidth":"450px","minHeight":"35vh","width":"450px","titleStyle":`
      font-size: 16px !important;
      margin-left: 2%;
      color: ${_vm.colorCodes.titleColor};
    `,"closeButtonStyle":"padding: 2%;","noMargin":""},on:{"popup-back":function($event){return _vm.openDetailsWindow(false)},"popup-close":function($event){return _vm.openDetailsWindow(false)}}},[_c('div',{staticClass:"details-window"},[_c('div',{staticClass:"details-container"},[_c('div',{staticClass:"item-data-inpopup"},[_c('h2',{staticClass:"item-name",style:({
              textAlign: 'left',
              color:
                !_vm.entireTraceUnverified && _vm.item.verified
                  ? _vm.colorCodes.verifiedColor
                  : _vm.colorCodes.unverifiedColor,
            })},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),(_vm.item.city && _vm.item.country)?_c('div',{staticClass:"sub-title-container"},[_c('location-icon',{staticClass:"location-icon",attrs:{"fill":!_vm.entireTraceUnverified && _vm.item.verified
                  ? _vm.colorCodes.verifiedColor
                  : _vm.colorCodes.unverifiedColor}}),_c('span',{staticClass:"sub-title-text tractable",style:({
                color:
                  !_vm.entireTraceUnverified && _vm.item.verified
                    ? _vm.colorCodes.verifiedColor
                    : _vm.colorCodes.unverifiedColor,
              })},[_vm._v(_vm._s(_vm.item.city)+","+_vm._s(" ")+_vm._s(_vm.item.country))])],1):_vm._e()])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }