var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"}},[_c('div',{class:`compact-item-header ${_vm.isCompact ? 'clickable-header' : ''}`,style:({ backgroundColor: _vm.colorCodes.backgroundColor }),on:{"click":_vm.toggleExpand}},[(!_vm.isMobile)?_c('div',{staticClass:"icon-container",style:({ ...(_vm.isCompact ? { flex: 1 } : {}) })},[_c('ellipse-icon',{attrs:{"width":'59px',"height":'59px',"border":"2px","variant":_vm.colorCodes.iconColor,"fill":_vm.colorCodes.backgroundColor}},[_c(_vm.icon,{tag:"component",attrs:{"fill":_vm.colorCodes.iconColor}})],1),_c('span',{staticClass:"more-info-title",style:({
          color: _vm.colorCodes.titleColor,
          borderBottom: `1px solid ${_vm.colorCodes.iconColor}`,
          ...(_vm.isCompact ? { width: '100%' } : {}),
        })},[_vm._v(_vm._s(_vm.title))])],1):_c('div',{staticClass:"icon-container title-underline",style:({
        borderBottom: `1px solid ${_vm.colorCodes.iconColor}`,
        ...(_vm.isCompact ? { flex: 1 } : {}),
      })},[(!_vm.iconSize)?_c(_vm.icon,{tag:"component",attrs:{"fill":_vm.colorCodes.iconColor}}):_c(_vm.icon,{tag:"component",attrs:{"fill":_vm.colorCodes.iconColor,"width":_vm.iconSize,"height":_vm.iconSize}}),_c('span',{staticClass:"more-info-title-mobile",style:({
          color: _vm.colorCodes.titleColor,
          ...(_vm.isCompact ? { width: '100%' } : {}),
        })},[_vm._v(_vm._s(_vm.title))])],1),(_vm.isCompact)?_c('div',{staticClass:"expand-button"},[_c('v-icon',{staticClass:"expand-icon",style:({ color: _vm.colorCodes.iconColor })},[_vm._v(_vm._s(_vm.expanded ? "mdi-minus" : "mdi-plus"))])],1):_vm._e()]),(!_vm.isCompact || _vm.expanded)?_vm._t("default"):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }