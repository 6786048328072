var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bom-item-container mb-4",style:(`min-height: ${_vm.customHeight + 19}px;`)},[_c('div',{staticClass:"embed",style:(`flex: 0 0 ${_vm.customHeight * (135 / 81) + 4}px; height: ${
      _vm.customHeight + 21
    }px; border: 0.5px solid ${_vm.colorCodes.iconColor};`)},[(_vm.bomItem.main_image)?_c('img',{staticClass:"centered-and-cropped",style:(`height:${_vm.customHeight}px; width: ${
        _vm.customHeight * (135 / 81)
      }px;`),attrs:{"src":_vm.getImage(),"alt":"bom item"}}):_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","width":"100%","height":"100%"}},[_c('bom-item-generic-icon',{attrs:{"tag":_vm.getGenericImage(),"height":_vm.customHeight + 'px',"width":_vm.customHeight * (135 / 81) + 'px',"fill":_vm.colorCodes.iconColor,"background":_vm.genericItemBackgroundColor,"pageColor":_vm.colorCodes.backgroundColor}})],1)]),_c('div',{staticClass:"bom-data-container",style:(`${
      !_vm.expanded ? 'max-height: ' + (_vm.customHeight + 17) + 'px;' : ''
    }`),attrs:{"id":`bom-trace-item-${_vm.bomItem.id}`}},[_c('h2',{staticClass:"item-name",style:({ color: _vm.colorCodes.titleColor })},[_vm._v(" "+_vm._s(_vm.bomItem.name)+" ")]),_vm._l((_vm.bomItem.factories),function(factory,index){return _c('div',{key:index,staticClass:"bom-data"},[(factory.city && factory.country)?_c('div',{staticClass:"sub-title-container",attrs:{"id":`bom-trace-item-${_vm.bomItem.id}-${factory.id}-location`}},[_c('location-icon',{staticClass:"location-icon",attrs:{"fill":!_vm.entireTraceUnverified && factory.verified
              ? _vm.colorCodes.verifiedColor
              : _vm.colorCodes.unverifiedColor}}),_c('span',{staticClass:"sub-title-text tractable",style:({
            color:
              !_vm.entireTraceUnverified && factory.verified
                ? _vm.colorCodes.verifiedColor
                : _vm.colorCodes.unverifiedColor,
          }),attrs:{"id":`bom-trace-item-${_vm.bomItem.id}-${factory.id}-location-span`}},[_vm._v(_vm._s(factory.city)+","+_vm._s(" ")+_vm._s(factory.country))])],1):_vm._e(),(factory.certifications.length)?_c('div',{staticClass:"certifications"},[_c('div',{staticClass:"sub-title-container",attrs:{"id":`bom-trace-item-${_vm.bomItem.id}-${factory.id}-certificate-title`}},[_c('certifications-icon',{staticClass:"certification-icon",attrs:{"fill":!_vm.entireTraceUnverified && factory.verified
                ? _vm.colorCodes.verifiedColor
                : _vm.colorCodes.unverifiedColor}}),_c('span',{staticClass:"sub-title-text tractable",style:({
              color:
                !_vm.entireTraceUnverified && factory.verified
                  ? _vm.colorCodes.verifiedColor
                  : _vm.colorCodes.unverifiedColor,
            }),attrs:{"id":`bom-trace-item-${_vm.bomItem.id}-${factory.id}-certificate-title-span`}},[_vm._v("Certifications:")])],1),_c('div',{staticClass:"certificates-container"},_vm._l((factory.certifications),function(certificate){return _c('div',{key:certificate.id,staticStyle:{"max-width":"100%"},attrs:{"id":`bom-trace-item-${_vm.bomItem.id}-${factory.id}-certificate-${certificate.id}`}},[_c('div',{staticClass:"certification-list",style:(!_vm.entireTraceUnverified && factory.verified
                  ? _vm.verifiedCertificationStyles
                  : _vm.unverifiedCertificationStyles)},[_c('span',{staticClass:"tractable",attrs:{"id":`bom-trace-item-${_vm.bomItem.id}-${factory.id}-certificate-${certificate.id}-span`}},[_vm._v(_vm._s(certificate.caption))])])])}),0)]):_vm._e()])})],2),_c('div',{staticClass:"expand-icon-container",style:(`height: ${_vm.customHeight + 19}px; ${
      _vm.displayExpand || _vm.showGeoTagIcon
        ? `display: flex; ${!_vm.displayExpand ? 'flex-direction: column;' : ''}`
        : 'display: none;'
    }`)},[(_vm.displayExpand && !_vm.expandWithPopup)?_c('div',{staticClass:"expand-icon",on:{"click":_vm.changeContainerHeight}},[_c('v-icon',{style:({ color: _vm.colorCodes.titleColor })},[_vm._v(_vm._s(_vm.expanded ? "mdi-menu-up" : "mdi-menu-down"))])],1):(_vm.displayExpand)?_c('div',{staticClass:"factories-show-btn mt-2",on:{"click":function($event){return _vm.openFactoryWindow(true)}}},[_c('v-icon',{style:({ color: _vm.colorCodes.titleColor })},[_vm._v("mdi-open-in-new")])],1):_vm._e(),(_vm.showGeoTagIcon)?_c('div',[_c('div',{staticClass:"geo-image-selector-button",on:{"click":function($event){$event.preventDefault();return _vm.emitGeoTagEvent(_vm.bomItem.id)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('geo-tagging-icon')],1)]}}],null,false,2198308196)},[_c('div',{staticClass:"hover-container"},[_c('span',[_vm._v("Add a geo tag image")])])])],1)]):_vm._e()]),_c('popup-window',{attrs:{"active":_vm.factoriesWindow,"title":"Suppliers Information","hasBack":false,"maxWidth":"450px","minHeight":"35vh","width":"450px","titleStyle":`
      font-size: 16px !important;
      margin-left: 2%;
      color: ${_vm.colorCodes.titleColor};
    `,"closeButtonStyle":"padding: 2%;","noMargin":""},on:{"popup-back":function($event){return _vm.openFactoryWindow(false)},"popup-close":function($event){return _vm.openFactoryWindow(false)}}},[_c('div',{staticClass:"factories-window"},[_c('div',{staticClass:"factories-container"},_vm._l((_vm.bomItem.factories),function(factory,index){return _c('div',{key:index,staticClass:"bom-data-inpopup"},[(factory.city && factory.country)?_c('div',{staticClass:"sub-title-container"},[_c('location-icon',{staticClass:"location-icon",attrs:{"fill":!_vm.entireTraceUnverified && factory.verified
                  ? _vm.colorCodes.verifiedColor
                  : _vm.colorCodes.unverifiedColor}}),_c('span',{staticClass:"sub-title-text tractable",style:({
                color:
                  !_vm.entireTraceUnverified && factory.verified
                    ? _vm.colorCodes.verifiedColor
                    : _vm.colorCodes.unverifiedColor,
              })},[_vm._v(_vm._s(factory.city)+","+_vm._s(" ")+_vm._s(factory.country))])],1):_vm._e(),(factory.certifications.length)?_c('div',{staticClass:"certifications"},[_c('div',{staticClass:"sub-title-container"},[_c('certifications-icon',{staticClass:"certification-icon",attrs:{"fill":!_vm.entireTraceUnverified && factory.verified
                    ? _vm.colorCodes.verifiedColor
                    : _vm.colorCodes.unverifiedColor}}),_c('span',{staticClass:"sub-title-text tractable",style:({
                  color:
                    !_vm.entireTraceUnverified && factory.verified
                      ? _vm.colorCodes.verifiedColor
                      : _vm.colorCodes.unverifiedColor,
                })},[_vm._v("Certifications:")])],1),_c('div',{staticClass:"certificates-container"},_vm._l((factory.certifications),function(certificate){return _c('div',{key:certificate.id,staticClass:"certification-list",style:(!_vm.entireTraceUnverified && factory.verified
                    ? _vm.verifiedCertificationStyles
                    : _vm.unverifiedCertificationStyles)},[_c('span',[_vm._v(_vm._s(certificate.caption))])])}),0)]):_vm._e()])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }