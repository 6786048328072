<template>
  <div
    class="bom-item-container mb-4"
    :style="`min-height: ${customHeight + 19}px;`"
  >
    <div
      class="embed"
      :style="`flex: 0 0 ${customHeight * (135 / 81) + 4}px; height: ${
        customHeight + 21
      }px; border: 0.5px solid ${colorCodes.iconColor};`"
    >
      <img
        v-if="bomItem.main_image"
        class="centered-and-cropped"
        :style="`height:${customHeight}px; width: ${
          customHeight * (135 / 81)
        }px;`"
        :src="getImage()"
        alt="bom item"
      />
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        "
        v-else
      >
        <bom-item-generic-icon
          :tag="getGenericImage()"
          :height="customHeight + 'px'"
          :width="customHeight * (135 / 81) + 'px'"
          :fill="colorCodes.iconColor"
          :background="genericItemBackgroundColor"
          :pageColor="colorCodes.backgroundColor"
        />
      </div>
    </div>
    <div
      :id="`bom-trace-item-${bomItem.id}`"
      class="bom-data-container"
      :style="`${
        !expanded ? 'max-height: ' + (customHeight + 17) + 'px;' : ''
      }`"
    >
      <h2 class="item-name" :style="{ color: colorCodes.titleColor }">
        {{ bomItem.name }}
      </h2>
      <div
        class="bom-data"
        v-for="(factory, index) in bomItem.factories"
        :key="index"
      >
        <div
          v-if="factory.city && factory.country"
          :id="`bom-trace-item-${bomItem.id}-${factory.id}-location`"
          class="sub-title-container"
        >
          <location-icon
            class="location-icon"
            :fill="
              !entireTraceUnverified && factory.verified
                ? colorCodes.verifiedColor
                : colorCodes.unverifiedColor
            "
          />
          <span
            :id="`bom-trace-item-${bomItem.id}-${factory.id}-location-span`"
            class="sub-title-text tractable"
            :style="{
              color:
                !entireTraceUnverified && factory.verified
                  ? colorCodes.verifiedColor
                  : colorCodes.unverifiedColor,
            }"
            >{{ factory.city }},{{ " " }}{{ factory.country }}</span
          >
        </div>

        <div v-if="factory.certifications.length" class="certifications">
          <div
            :id="`bom-trace-item-${bomItem.id}-${factory.id}-certificate-title`"
            class="sub-title-container"
          >
            <certifications-icon
              class="certification-icon"
              :fill="
                !entireTraceUnverified && factory.verified
                  ? colorCodes.verifiedColor
                  : colorCodes.unverifiedColor
              "
            />
            <span
              :id="`bom-trace-item-${bomItem.id}-${factory.id}-certificate-title-span`"
              class="sub-title-text tractable"
              :style="{
                color:
                  !entireTraceUnverified && factory.verified
                    ? colorCodes.verifiedColor
                    : colorCodes.unverifiedColor,
              }"
              >Certifications:</span
            >
          </div>
          <div class="certificates-container">
            <div
              :id="`bom-trace-item-${bomItem.id}-${factory.id}-certificate-${certificate.id}`"
              style="max-width: 100%"
              v-for="certificate in factory.certifications"
              :key="certificate.id"
            >
              <div
                class="certification-list"
                :style="
                  !entireTraceUnverified && factory.verified
                    ? verifiedCertificationStyles
                    : unverifiedCertificationStyles
                "
              >
                <span
                  :id="`bom-trace-item-${bomItem.id}-${factory.id}-certificate-${certificate.id}-span`"
                  class="tractable"
                  >{{ certificate.caption }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="expand-icon-container"
      :style="`height: ${customHeight + 19}px; ${
        displayExpand || showGeoTagIcon
          ? `display: flex; ${!displayExpand ? 'flex-direction: column;' : ''}`
          : 'display: none;'
      }`"
    >
      <div
        v-if="displayExpand && !expandWithPopup"
        class="expand-icon"
        @click="changeContainerHeight"
      >
        <v-icon :style="{ color: colorCodes.titleColor }">{{
          expanded ? "mdi-menu-up" : "mdi-menu-down"
        }}</v-icon>
      </div>
      <div
        v-else-if="displayExpand"
        class="factories-show-btn mt-2"
        @click="openFactoryWindow(true)"
      >
        <v-icon :style="{ color: colorCodes.titleColor }"
          >mdi-open-in-new</v-icon
        >
      </div>
      <div v-if="showGeoTagIcon">
        <div
          class="geo-image-selector-button"
          @click.prevent="emitGeoTagEvent(bomItem.id)"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <geo-tagging-icon />
              </div>
            </template>
            <div class="hover-container">
              <span>Add a geo tag image</span>
            </div>
          </v-tooltip>
        </div>
      </div>
    </div>
    <popup-window
      :active="factoriesWindow"
      title="Suppliers Information"
      :hasBack="false"
      maxWidth="450px"
      minHeight="35vh"
      width="450px"
      :titleStyle="`
        font-size: 16px !important;
        margin-left: 2%;
        color: ${colorCodes.titleColor};
      `"
      closeButtonStyle="padding: 2%;"
      noMargin
      @popup-back="openFactoryWindow(false)"
      @popup-close="openFactoryWindow(false)"
    >
      <div class="factories-window">
        <div class="factories-container">
          <div
            class="bom-data-inpopup"
            v-for="(factory, index) in bomItem.factories"
            :key="index"
          >
            <div
              v-if="factory.city && factory.country"
              class="sub-title-container"
            >
              <location-icon
                class="location-icon"
                :fill="
                  !entireTraceUnverified && factory.verified
                    ? colorCodes.verifiedColor
                    : colorCodes.unverifiedColor
                "
              />
              <span
                class="sub-title-text tractable"
                :style="{
                  color:
                    !entireTraceUnverified && factory.verified
                      ? colorCodes.verifiedColor
                      : colorCodes.unverifiedColor,
                }"
                >{{ factory.city }},{{ " " }}{{ factory.country }}</span
              >
            </div>

            <div v-if="factory.certifications.length" class="certifications">
              <div class="sub-title-container">
                <certifications-icon
                  class="certification-icon"
                  :fill="
                    !entireTraceUnverified && factory.verified
                      ? colorCodes.verifiedColor
                      : colorCodes.unverifiedColor
                  "
                />
                <span
                  class="sub-title-text tractable"
                  :style="{
                    color:
                      !entireTraceUnverified && factory.verified
                        ? colorCodes.verifiedColor
                        : colorCodes.unverifiedColor,
                  }"
                  >Certifications:</span
                >
              </div>
              <div class="certificates-container">
                <div
                  class="certification-list"
                  :style="
                    !entireTraceUnverified && factory.verified
                      ? verifiedCertificationStyles
                      : unverifiedCertificationStyles
                  "
                  v-for="certificate in factory.certifications"
                  :key="certificate.id"
                >
                  <span>{{ certificate.caption }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </popup-window>
  </div>
</template>

<script>
import * as conf from "../../conf.yml";
import { hexToRGBA } from "../../utils/helper";

export default {
  name: "BomItemTrace",
  data: () => ({
    otherImage: "other-icon",
    bomImages: {
      thread: "thread-icon",
      button: "button-icon",
      zipper: "zipper-icon",
      drawcord: "drawcord-icon",
      mainLabel: "main-label-icon",
      careLabel: "care-label-icon",
      handTag: "hang-tag-icon",
      hangTagThread: "hang-tag-thread-icon",
      packaging: "packaging-icon",
      carton: "carton-icon",
      body: "body-icon",
      lining: "lining-icon",
    },
    baseUrl: conf.default.server.host,
    inquiryId: null,
    displayExpand: false,
    expanded: false,
    factoriesWindow: false,
  }),

  props: {
    bomItem: {
      type: Object,
      default: () => ({}),
    },
    expandWithPopup: {
      type: Boolean,
      default: false,
    },
    entireTraceUnverified: {
      type: Boolean,
      default: false,
    },
    showGeoTagIcon: {
      type: Boolean,
      default: false,
    },
    customHeight: {
      type: Number,
      default: 81,
    },
    colorCodes: {
      type: Object,
      default: () => ({
        titleColor: "#473068",
        iconColor: "#473068",
        verifiedColor: "#473068",
        unverifiedColor: "#EA7A66",
        backgroundColor: "#FFFFFF",
      }),
    },
  },

  watch: {
    expanded: {
      handler: function (val) {
        this.hideHalfContents();
      },
    },
  },

  computed: {
    genericItemBackgroundColor() {
      const iconColorAsRGBA = hexToRGBA(this.colorCodes.iconColor);
      return `rgba(${iconColorAsRGBA.r}, ${iconColorAsRGBA.g}, ${iconColorAsRGBA.b}, 0.1)`;
    },
    verifiedCertificationStyles() {
      const backgroundColor = hexToRGBA(this.colorCodes.verifiedColor);
      return `color: ${
        this.colorCodes.verifiedColor
      }; background-color: ${`rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, 0.1)`}; border: 0.5px solid ${
        this.colorCodes.verifiedColor
      };`;
    },
    unverifiedCertificationStyles() {
      const backgroundColor = hexToRGBA(this.colorCodes.unverifiedColor);
      return `color: ${
        this.colorCodes.unverifiedColor
      }; background-color: ${`rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, 0.1)`}; border: 0.5px solid ${
        this.colorCodes.unverifiedColor
      };`;
    },
  },

  methods: {
    windowSizeReactor() {
      this.checkExpand();
      this.hideHalfContents();
    },
    emitGeoTagEvent(itemId) {
      this.$emit("geo-tag-clicked", itemId);
    },
    openFactoryWindow(value) {
      this.factoriesWindow = value;
    },
    getImage() {
      if (this.bomItem.main_image)
        return `${this.baseUrl}/api/inquiries/file_show/${this.bomItem.main_image}`;
    },
    getGenericImage() {
      if (this.bomItem.generic_image)
        return this.bomImages[this.bomItem.generic_image];
      else return this.otherImage;
    },
    checkExpand() {
      this.hideHalfContents({ reset: true });
      const bomItem = document.getElementById(
        `bom-trace-item-${this.bomItem.id}`
      );
      if (this.showGeoTagIcon) {
        bomItem.style.maxWidth = `calc(100% - ${
          this.customHeight * (135 / 81) + 4
        }px - 30px)`;
      }

      if (bomItem.scrollHeight > this.customHeight + 17) {
        bomItem.style.maxWidth = `calc(100% - ${
          this.customHeight * (135 / 81) + 4
        }px - 30px)`;
        this.displayExpand = true;
      } else {
        if (this.showGeoTagIcon) {
          bomItem.style.maxWidth = `calc(100% - ${
            this.customHeight * (135 / 81) + 4
          }px)`;
        }
        this.displayExpand = false;
      }
    },
    changeContainerHeight() {
      this.hideHalfContents({ reset: true });
      const bomItem = document.getElementById(
        `bom-trace-item-${this.bomItem.id}`
      );
      if (this.expanded) {
        bomItem.style.maxHeight = `${this.customHeight + 17}px`;
        this.expanded = false;
      } else {
        bomItem.style.maxHeight = `${bomItem.scrollHeight}px`;
        this.expanded = true;
      }
    },
    hideHalfContents(options) {
      const bomItemContainerBottom = document
        .getElementById(`bom-trace-item-${this.bomItem.id}`)
        .getBoundingClientRect().bottom;
      for (let i = 0; i < this.bomItem.factories.length; i++) {
        const factory = this.bomItem.factories[i];
        const locationElement = document.getElementById(
          `bom-trace-item-${this.bomItem.id}-${factory.id}-location`
        );
        const childLocationSpan = document.getElementById(
          `bom-trace-item-${this.bomItem.id}-${factory.id}-location-span`
        );
        if (locationElement) {
          childLocationSpan.style.webkitLineClamp = "unset";
          locationElement.style.width = "unset";
          locationElement.style.overflow = "unset";
        }

        if (
          !options?.reset &&
          locationElement &&
          locationElement.getBoundingClientRect().bottom >
            bomItemContainerBottom
        ) {
          this.isNeedToHideLineReducedElement(
            bomItemContainerBottom,
            locationElement,
            childLocationSpan
          );
        }

        const captionElement = document.getElementById(
          `bom-trace-item-${this.bomItem.id}-${factory.id}-certificate-title`
        );
        const childCaptionSpan = document.getElementById(
          `bom-trace-item-${this.bomItem.id}-${factory.id}-certificate-title-span`
        );
        if (captionElement) {
          childCaptionSpan.style.webkitLineClamp = "unset";
          captionElement.style.width = "unset";
          captionElement.style.overflow = "unset";
        }

        if (
          !options?.reset &&
          captionElement &&
          captionElement.getBoundingClientRect().bottom > bomItemContainerBottom
        ) {
          this.isNeedToHideLineReducedElement(
            bomItemContainerBottom,
            captionElement,
            childCaptionSpan
          );
        }

        for (let j = 0; j < factory.certifications.length; j++) {
          const certificate = factory.certifications[j];
          const certificateElement = document.getElementById(
            `bom-trace-item-${this.bomItem.id}-${factory.id}-certificate-${certificate.id}`
          );
          const childCertificateSpan = document.getElementById(
            `bom-trace-item-${this.bomItem.id}-${factory.id}-certificate-${certificate.id}-span`
          );
          if (certificateElement) {
            childCertificateSpan.style.webkitLineClamp = "unset";
            certificateElement.style.width = "unset";
            certificateElement.style.overflow = "unset";
          }
          if (
            !options?.reset &&
            certificateElement &&
            certificateElement.getBoundingClientRect().bottom >
              bomItemContainerBottom
          ) {
            this.isNeedToHideLineReducedElement(
              bomItemContainerBottom,
              certificateElement,
              childCertificateSpan
            );
          }
        }
      }
    },
    reduceLineClamp(element) {
      const computedStyle = window.getComputedStyle(element);
      const paddingTop = parseInt(computedStyle.paddingTop);
      const paddingBottom = parseInt(computedStyle.paddingBottom);
      const borderTop = parseInt(computedStyle.borderTopWidth);
      const borderBottom = parseInt(computedStyle.borderBottomWidth);
      const lineHeight = parseInt(computedStyle.lineHeight);
      const boundingRect = element.getBoundingClientRect();
      const height =
        boundingRect.height -
        paddingTop -
        paddingBottom -
        borderTop -
        borderBottom;
      const numberOfLines = Math.round(height / lineHeight);
      const reducedByOne = numberOfLines - 1;
      if (reducedByOne > 0) {
        element.style.webkitLineClamp = reducedByOne;
        return true;
      }
      return false;
    },
    isNeedToHideLineReducedElement(containerBottom, parentElement, childSpan) {
      let hideLocation = true;
      let reduceLocationLineClamp = true;
      while (reduceLocationLineClamp) {
        reduceLocationLineClamp = this.reduceLineClamp(childSpan);
        if (parentElement.getBoundingClientRect().bottom <= containerBottom) {
          reduceLocationLineClamp = false;
          hideLocation = false;
        }
      }

      if (hideLocation) {
        parentElement.style.width = "0px";
        parentElement.style.overflow = "hidden";
        return true;
      }

      return false;
    },
  },

  created() {
    window.addEventListener("resize", this.windowSizeReactor);
  },

  mounted() {
    this.windowSizeReactor();
  },

  destroyed() {
    window.removeEventListener("resize", this.windowSizeReactor);
  },
};
</script>

<style lang="scss" scoped>
.bom-item-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.bom-data-container {
  max-width: 100%;
  width: 100%;
  padding: 0px 0px 0px 12px;
  text-align: left;
  overflow-wrap: anywhere;
  overflow-y: hidden;
}

.expand-icon-container {
  margin-right: 10px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: space-between;
}

.expand-icon {
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}

.v-icon.v-icon {
  color: $primary;
}

.bom-data {
  color: #473068;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 8px;
}

.embed {
  border-radius: 7px;
  text-align: center;
  position: relative;
}

.sub-title-container {
  display: flex;
  align-items: center;
}

.sub-title-text {
  margin-left: 5px;
  line-height: 14px;
  margin-bottom: 2px;
  text-align: left;
  overflow-wrap: anywhere;
}

.centered-and-cropped {
  object-fit: contain;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.item-name {
  font-size: 15px;
  margin-bottom: 2px;
}
.location-icon {
  margin-bottom: -1px;
  margin-right: 4px;
  flex: 0 0 10px;
}
.certification-icon {
  flex: 0 0 14px;
}
.certifications {
  color: #473068;
}
.certificates-container {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 3px;
}
.tractable {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.certificate-details {
  color: #473068;
  background-color: #efefef;
  width: fit-content;
  font-size: 10px;
  min-height: 20px;
  height: fit-content;
  margin: 2px 2px 0px 0px;
  padding: 2px;
  border-radius: 18px;
  border: 0.5px solid #442d64;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  overflow-wrap: anywhere;
}
.certification-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 5px 2px 5px;
  margin: 0px 3px 3px 0px;
  border-radius: 3px;
  overflow-wrap: anywhere;
  line-height: 14px;
  max-width: 100%;
}
.factories-show-btn {
  width: fit-content;
  height: fit-content;
  padding: 1px;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}
.factories-window {
  height: fit-content;
  padding: 0px 2%;
}
.factories-container {
  margin-top: 15px;
}
.bom-data-inpopup {
  color: #473068;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 10px;
  border-top: 1px solid rgba(71, 48, 104, 0.1);
  padding: 5px;
  padding-top: 15px;
}
.geo-image-selector-button {
  cursor: pointer;
}
.geo-image-selector-button:hover {
  svg {
    height: 30px;
    width: 30px;
  }
}
</style>
