<template>
  <div>
    <div class="card-container md-layout">
      <md-card
        class="md-layout-item md-large-size-40 md-size-50 md-small-size-60 md-xsmall-size-100 transparent-background"
      >
        <md-card-header>
          <v-row>
            <v-col>
              <div class="powered-by">
                <div class="powered-by-label">Powered By</div>
                <v-spacer></v-spacer>
                <a href="https://seamlesssource.com/">
                  <img src="../../assets/seamless-source-banner.png" alt="Seamless Source" />
                </a>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="title-container">
                <div class="traceability">Digital Product Passport</div>
                <hr class="hr_devider_horizontal"/>
              </div>
            </v-col>
          </v-row>
        </md-card-header>

        <md-card-content>
          <div class="message-text">Digital Product Passport is pending. Please try again later.</div>
        </md-card-content>

        <md-card-actions class="action-container">
          <ui-button class="primary short" @click="goToHome" title="Go to home"/>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "TracePending",
  data: () => ({
    logoImg: require(`../../assets/seamless-source-banner.png`),
  }),
  methods: {
    goToHome() {
      this.$router.push("/app");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  padding-top: 100px;
  justify-content: center;
}
.transparent-background {
  background: rgba(255, 255, 255, 0.9) !important;
}
.powered-by {
  display: flex;
  max-width: 100px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  float: right;
}
.powered-by-label {
  text-align: left;
  font-size: 12px;
  margin-bottom: -3px;
  color: #473068;
}
.message-text {
  text-align: left;
  margin: 0px 10px 0px 0px;
  line-height: 38px;
  color: #473068;
}
.traceability {
  text-align: left;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #70D0CE;
}
.hr_devider_horizontal{
  background: linear-gradient(to right, #473068, rgba(71, 48, 104, 0));
  height: 3px;
  width: 90%;
  border: none;
}
.action-container {
  margin: 12px;
  margin-top: 50px;
}
</style>
